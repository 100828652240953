import * as React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import "../style.css";

// markup
const Privacy = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Thank You</title>
      </Helmet>
      <main className="text-gray-700">
        <div className="py-8 mx-auto prose">
          <div className="sticky top-0">
            <Link className="block p-4 bg-gray-200" to="/">
              return to VicPeterson.com
            </Link>
          </div>
          <p>Your message has been sent. Thank you!</p>
        </div>
      </main>
      <footer className="p-4 text-sm text-center text-gray-600">
        ©2021 Vic Peterson
      </footer>
    </>
  );
};

export default Privacy;
